<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" md="6">
          <span
            class="text-h6 ml-1"
            v-text="`${$route.meta.title} ${loading ? '' : `(${total_rows})`}`"
          />
        </v-col>
        <v-col cols="12" md="6" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="login.role_id == 1" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="teal"
                dark
                :loading="btn_xls_ldg"
                :disabled="data.length == 0"
                @click.prevent="downloadXls(1)"
              >
                <v-icon> mdi-table-arrow-down </v-icon>
              </v-btn>
            </template>
            <span v-text="'Descargar tabla XLS 1'" />
          </v-tooltip>
          <v-tooltip v-if="login.role_id == 1" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="cyan"
                dark
                :loading="btn_xls_ldg"
                :disabled="data.length == 0"
                @click.prevent="downloadXls(2)"
              >
                <v-icon> mdi-table-arrow-down </v-icon>
              </v-btn>
            </template>
            <span v-text="'Descargar tabla XLS 2'" />
          </v-tooltip>
          <BtnCircle
            tLabel="Concentradores"
            tPosition="bottom"
            icon="mdi-group"
            color="teal darken-3"
            size="x-small"
            :to="{ name: 'proveedores.concentradores' }"
          />
          <BtnCircle
            tLabel="Tipos de prestación"
            tPosition="bottom"
            icon="mdi-checkerboard"
            color="brown darken-3"
            size="x-small"
            :to="{ name: 'proveedores.tipos_prestaciones' }"
          />
          <v-tooltip v-if="login.permissions.benefits.read" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="pink"
                dark
                :to="{ name: 'providers.search_benefits' }"
              >
                <v-icon> mdi-magnify-expand </v-icon>
              </v-btn>
            </template>
            <span v-text="'Buscar | Prestaciones'" />
          </v-tooltip>
          <BtnCircle
            tLabel="Coberturas"
            tPosition="bottom"
            icon="mdi-file-certificate"
            color="purple darken-3"
            size="x-small"
            :to="{ name: 'proveedores.coberturas' }"
          />
          <BtnCircle
            tLabel="Tipos"
            tPosition="bottom"
            icon="mdi-clipboard-list"
            color="info"
            size="x-small"
            :to="{ name: 'proveedores.tipos' }"
          />
          <BtnCircle
            v-if="login.permissions.provider_groups.read"
            tLabel="Redes"
            tPosition="bottom"
            icon="mdi-table-network"
            color="warning"
            size="x-small"
            :to="{ name: 'redes.proveedores' }"
          />
          <BtnCircle
            v-if="login.permissions.providers.create"
            tLabel="Crear"
            tPosition="bottom"
            icon="mdi-plus"
            color="success"
            size="x-small"
            :to="{ name: `${page_route}.store` }"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="3">
          <v-select
            label="Estado"
            v-model="filter"
            :items="filters"
            item-value="id"
            :item-text="(v) => v.name"
            dense
            @change="getTableData"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            label="Tipo"
            v-model="provider_type_id"
            :items="provider_types"
            item-value="id"
            :item-text="(v) => v.provider_type"
            dense
            @change="getTableData"
            :loading="provider_types_ldg"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            label="Contratante"
            v-model="contractor_id"
            :items="contractors"
            item-value="id"
            :item-text="(v) => v.name"
            dense
            @change="getTableData"
            :loading="contractors_ldg"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="data"
            :search="search"
            :loading="loading"
            :item-class="itemBG"
          >
            <template v-slot:item.index="{ index }">
              <b>{{ index + 1 }}</b>
            </template>
            <template v-slot:item.active="{ item }">
              <v-icon small :color="item.active ? 'success' : 'error'">
                mdi-circle
              </v-icon>
            </template>
            <template v-slot:item.provider_brand="{ item }">
              <v-icon
                v-if="!item.provider_brand"
                small
                color="orange"
                v-text="'mdi-alert'"
              />
              <span v-else v-text="item.provider_brand" />
            </template>
            <template v-slot:item.with_module="{ item }">
              <v-icon v-if="item.with_module" color="success" small>
                mdi-crosshairs-gps
              </v-icon>
              <v-icon v-else small> mdi-crosshairs-off </v-icon>
            </template>
            <template v-slot:item.observation="{ item }">
              <v-icon
                v-if="item.observation"
                small
                color="orange"
                v-text="'mdi-alert'"
              />
            </template>
            <template v-slot:item.cs="{ item }">
              <v-icon
                v-if="!item.cs"
                small
                color="orange"
                v-text="'mdi-alert'"
              />
              <span v-else v-text="item.cs" />
            </template>
            <template v-slot:item.revision="{ item }">
              <v-icon
                v-if="item.revision"
                small
                color="success"
                v-text="'mdi-check'"
              />
              <v-icon v-else small color="orange" v-text="'mdi-alert'" />
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    fab
                    x-small
                    color="primary"
                    :to="{
                      name: `${page_route}.view`,
                      params: { id: item.id },
                    }"
                  >
                    <v-icon v-text="'mdi-eye-outline'" />
                  </v-btn>
                </template>
                <span v-text="'Ver'" />
              </v-tooltip>
              <v-tooltip
                v-if="item.active && login.permissions.benefits.read"
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ml-1"
                    v-on="on"
                    fab
                    x-small
                    color="warning"
                    :to="{
                      name: 'prestaciones',
                      params: { provider_id: item.id },
                    }"
                  >
                    <v-icon v-text="'mdi-file'" />
                  </v-btn>
                </template>
                <span v-text="'Lista de precios'" />
              </v-tooltip>
            </template>

            <template v-slot:item.general="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <span v-text="item.name + '. '" /> <br />
                  <span v-text="item.trade_name" />
                </template>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
//import functions
import Axios from "axios";
import {
  URL_API,
  headersToken,
  base64ToArrayBuffer,
  dateTimeToFile,
} from "../../control";
import { index } from "../../requests/pageRequest";
//import components
import BtnCircle from "../../components/BtnCircle.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    FaqDlg,
  },
  data() {
    return {
      //route name and page title
      page_route: "providers",
      //vars control
      login: this.$store.getters.getLogin,
      loading: false,
      //data from api
      data: [],
      total_rows: 0,
      //vars table
      search: "",
      headers: [
        {
          text: "#",
          value: "index",
          filterable: false,
          sortable: false,
          width: "60px",
        },
        {
          text: "IDP",
          value: "id",
        },
        {
          text: "Activo",
          value: "active",
        },
        {
          text: "Nombre comercial",
          value: "trade_name",
        },
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Estado",
          value: "entity",
        },
        {
          text: "Municipio",
          value: "municipality",
        },
        {
          text: "Tipo",
          value: "provider_type",
        },
        {
          text: "Módulo SM",
          value: "with_module",
        },
        {
          text: "Concentrador",
          value: "provider_brand",
        },
        {
          text: "Observación",
          value: "observation",
        },
        {
          text: "C.S.",
          value: "cs",
        },
        {
          text: "Segunda firma red",
          value: "revision",
        },
        {
          text: "Acción",
          value: "action",
          filterable: false,
          sortable: false,
          width: "100px",
        },
      ],
      btn_xls_ldg: false,
      filter: 0,
      filters: [
        { id: 0, name: "TODOS" },
        { id: 1, name: "CON MÓDULO SM" },
      ],
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
      contractor_id: null,
      contractors: [],
      contractors_ldg: true,
      provider_type_id: null,
      provider_types: [],
      provider_types_ldg: true,
    };
  },
  methods: {
    downloadXls(type) {
      this.btn_xls_ldg = true;

      Axios.post(
        URL_API + "/providers/xls" + type,
        {
          items: this.data,
        },
        headersToken(this.login.token)
      ).then((res) => {
        if (res.data.success) {
          this.fileDownload(res.data.data, "xlsx", "asegurados");
          this.btn_xls_ldg = false;
        } else {
          this.btn_xls_ldg = false;
          this.$swal.fire(msgAlert("error", "Error al generar archixo XLSX"));
        }
      });
    },
    fileDownload(base64, ext, name) {
      const blob = new Blob([base64ToArrayBuffer(base64)], {
        type: "application/" + ext,
      });
      let downloadLink = document.createElement("a");
      downloadLink.setAttribute("target", "_blank");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "SM_" + name + "_" + dateTimeToFile() + "." + ext;
      downloadLink.click();
    },
    getTableData() {
      this.loading = true;

      index(this.page_route, this.login.token, [
        {
          name: "filter",
          value: this.filter,
        },
        {
          name: "provider_type_id",
          value: this.provider_type_id,
        },
        {
          name: "contractor_id",
          value: this.contractor_id,
        },
        {
          name: "id",
          value: this.login.id,
        },
      ]).then((response) => {
        this.data = response.data;
        this.total_rows = this.data.length;
        this.loading = false;
      });
    },
    itemBG(item) {
      return item.active ? "" : "red lighten-5";
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(URL_API + "/contractors", headersToken(this.login.token)).then(
      (rsp) => {
        this.contractors = rsp.data.data;
        this.contractors.push({
          name: "TODOS",
          id: null,
        });

        this.contractors_ldg = false;
      }
    );

    Axios.get(URL_API + "/provider/types", headersToken(this.login.token)).then(
      (rsp) => {
        this.provider_types = rsp.data.data;
        this.provider_types.push({
          provider_type: "TODOS",
          id: null,
        });

        this.provider_types_ldg = false;
      }
    );

    this.getTableData();
  },
};
</script>